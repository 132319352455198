import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { OffersListConnectorProps } from "./container";
import "./styles.scss";
import {
  TravelOfferCard,
  TravelOfferCardMinimal,
  TravelSalesEventBanner,
  TravelWalletDetailsModal,
  getTravelOfferCardContent,
  isOfferHSPTiered,
  useDeviceTypes,
} from "halifax";
import { InView } from "react-intersection-observer";
import { getPortalName } from "@capone/common";
import clsx from "clsx";
import {
  PATH_CARS_HOME,
  PATH_FLIGHTS_HOME,
  PATH_HOTELS_HOME,
  PATH_PREMIUM_STAYS,
  PATH_PREMIUM_VACATION_RENTALS,
} from "../../utils/paths";
import {
  Funnel,
  SelectedTravelOfferScreen,
  SELECTED_TRAVEL_OFFER,
  VIEWED_OFFERS_LIST,
  TravelWalletOffer,
  VIEWED_TRAVEL_OFFER,
  VIEW_CREDIT_HISTORY,
  ENGAGED_OFFER_CTA,
} from "redmond";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { CallState } from "../../../../modules/rewards/reducer";
import {
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_TRAVEL_SALE } from "../../../../utils/paths";
import * as textConstants from "../../textConstants";

export interface IOffersListProps
  extends OffersListConnectorProps,
    RouteComponentProps {}

export const OffersList = ({
  allOffers,
  activeOffers,
  areOfferDetailsLoading,
  fetchRewardsAccountsCallState,
}: IOffersListProps) => {
  const expState = useExperiments();
  const { matchesMobile } = useDeviceTypes();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const [selectedOffer, setSelectedOffer] = useState<
    | {
        offer: TravelWalletOffer;
        index?: number;
      }
    | undefined
  >(undefined);

  const history = useHistory();

  const offers =
    travelSalesEventVariant === TRAVEL_SALE_LEAD_UP ? activeOffers : allOffers;

  const getOfferCTAs = useCallback(
    (
      offer: TravelWalletOffer,
      includeViewOfferDetailsCTA?: boolean,
      offerRowIndex?: number
    ) => {
      const offerIndexToUse =
        offerRowIndex !== undefined ? offerRowIndex + 1 : undefined;
      const trackOfferEngagementEvent = () => {
        trackEvent({
          eventName: ENGAGED_OFFER_CTA,
          properties: {
            location: SelectedTravelOfferScreen.TRAVEL_WALLET_LANDING,
            entry_type:
              matchesMobile && !includeViewOfferDetailsCTA ? "modal" : "card",
            index_row_id: offerIndexToUse,
            funnel: offer.funnels.join(","),
          },
          encryptedProperties: [
            offer.trackingPropertiesV2?.encryptedProperties ?? "",
          ],
        });
      };
      const funnels = offer.funnels;
      const funnelButton = funnels.map((funnel) => {
        switch (funnel) {
          case Funnel.Air:
            return {
              text: "Search flights",
              onClick: () => {
                history.push(PATH_FLIGHTS_HOME);
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Lodging:
            if (
              offer.descriptions &&
              (offer.descriptions[0]
                .toLowerCase()
                .includes("premier collection") ||
                offer.descriptions[0]
                  .toLowerCase()
                  .includes("premier or lifestyle collection") ||
                offer.descriptions[0]
                  .toLowerCase()
                  .includes("lifestyle collection"))
            ) {
              return {
                text: "Search premium stays",
                onClick: () => {
                  history.push(PATH_PREMIUM_STAYS);
                  trackOfferEngagementEvent();
                },
              };
            }
            return {
              text: "Search hotels",
              onClick: () => {
                history.push(PATH_HOTELS_HOME);
                trackOfferEngagementEvent();
              },
            };
          case Funnel.Homes:  
          case Funnel.Home:
            return {
              text: "Search Vacation Rentals",
              onClick: () => {
                history.push(PATH_PREMIUM_VACATION_RENTALS);
                trackOfferEngagementEvent();
              },
            };  
          case Funnel.Ground:
            return {
              text: "Search cars",
              onClick: () => {
                history.push(PATH_CARS_HOME);
                trackOfferEngagementEvent();
              },
            };
        }
      });
      return [
        ...funnelButton,
        ...(includeViewOfferDetailsCTA
          ? [
              {
                text: "View offer details",
                onClick: () => {
                  setSelectedOffer({ offer, index: offerIndexToUse });
                  trackEvent({
                    eventName: SELECTED_TRAVEL_OFFER,
                    properties: {
                      screen: SelectedTravelOfferScreen.TRAVEL_WALLET_LANDING,
                      offer_count: offers.length,
                      ...offer.trackingProperties,
                    },
                  });

                  trackOfferEngagementEvent();
                },
              },
            ]
          : []),
      ];
    },
    [history]
  );

  useEffect(() => {
    if (fetchRewardsAccountsCallState === CallState.Success) {
      const bestOverallOffer = [...offers].sort(
        (a, b) => a.amount.amount - b.amount.amount
      )[0];

      trackEvent({
        eventName: VIEWED_OFFERS_LIST,
        properties: {
          has_offer: offers.length > 0,
          offer_count: offers.length,
          ...bestOverallOffer?.trackingProperties,
        },
      });
    }
  }, [offers, fetchRewardsAccountsCallState]);

  return (
    <Box className={clsx("offers-list-container", { mobile: matchesMobile })}>
      {isTravelWalletCreditsExperiment && !matchesMobile && (
        <Box
          className={clsx("offers-heading-container", {
            mobile: matchesMobile,
          })}
        >
          <Typography variant="h2" className="offers-heading-title">
            <strong>Travel offers</strong>
            {offers.length > 0 && <strong> ({offers.length})</strong>}
          </Typography>
          <Typography className="offers-heading-description">
            Travel offers are limited-time deals issued by Capital One that are
            applicable on future travel bookings.
          </Typography>
        </Box>
      )}
      <Box className={clsx("offers-list-cards", { mobile: matchesMobile })}>
        {!areOfferDetailsLoading && offers.length === 0 && (
          <TravelOfferCardMinimal
            title="You don’t have any travel offers."
            body="We’ll notify you when travel offers become available."
            ctas={[
              {
                text: `Explore ${getPortalName()}`,
                onClick: () => history.push("/"),
              },
            ]}
          />
        )}
        {travelSalesEventVariant === TRAVEL_SALE_ACTIVE && !matchesMobile && (
          <TravelSalesEventBanner
            variant={"default"}
            onClick={() =>
              matchesMobile
                ? history.push(PATH_TRAVEL_SALE)
                : window.open(PATH_TRAVEL_SALE, "_blank")
            }
            subtitle={textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
            buttonText={textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA}
            className={clsx("travel-sale-entry-point")}
          />
        )}
        {matchesMobile && selectedOffer && (
          <TravelWalletDetailsModal
            openModal={!!selectedOffer}
            setOpenModal={() => setSelectedOffer(undefined)}
            offerCardProps={{
              offerContent: getTravelOfferCardContent(selectedOffer.offer),
              ctas: getOfferCTAs(
                selectedOffer.offer,
                undefined,
                selectedOffer.index
              ),
              buttonClassName: "b2b",
              titleLabelText: isOfferHSPTiered(selectedOffer.offer.descriptions)
                ? textConstants.HSP_OFFER_TITLE_LABEL
                : undefined,
            }}
            termsType={matchesMobile ? "accordion" : "link"}
            isMobile
            offerFunnels={selectedOffer.offer.funnels}
            offerLocation={selectedOffer.offer.descriptions[2]}
            isTravelSale={travelSalesEventVariant !== CONTROL}
            imageId={selectedOffer.offer.imageId}
            onViewCreditHistory={() =>
              trackEvent({
                eventName: VIEW_CREDIT_HISTORY,
                properties: {},
              })
            }
          />
        )}
        {travelSalesEventVariant !== TRAVEL_SALE_ACTIVE &&
          offers.map((offer, i) => {
            const offerContent = getTravelOfferCardContent(offer);
            const offerLocation =
              offer.descriptions.length >= 3
                ? offer?.descriptions[2]
                : offer.descriptions[0];
            return (
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    trackEvent({
                      eventName: VIEWED_TRAVEL_OFFER,
                      properties: {
                        location: "travel-credits-and-offers-page",
                      },
                      encryptedProperties: [
                        offer?.trackingPropertiesV2?.encryptedProperties ?? "",
                      ],
                    });
                  }
                }}
                threshold={0.8}
              >
                {matchesMobile ? (
                  <TravelOfferCardMinimal
                    key={offer.id}
                    title={
                      isOfferHSPTiered(offer.descriptions)
                        ? `<strong>${offer.shopPageBanner}</strong>` ||
                          offer.title
                        : offer.title
                    }
                    expirationString={offerContent.expirationDetail}
                    validityString={offerContent.applicabilityDetail}
                    ctas={getOfferCTAs(offer, true, i)}
                    offerFunnels={offer.funnels}
                    offerLocation={offerLocation}
                    imageId={offer.imageId}
                  />
                ) : (
                  <Box className="offers-list-card-with-heading" key={offer.id}>
                    <TravelOfferCard
                      offerContent={offerContent}
                      showAccordian={false}
                      ctas={getOfferCTAs(offer, undefined, i)}
                      buttonClassName="b2b"
                      offerFunnels={offer.funnels}
                      offerLocation={offerLocation}
                      imageId={offer.imageId}
                      titleLabelText={
                        isOfferHSPTiered(offer.descriptions)
                          ? textConstants.HSP_OFFER_TITLE_LABEL
                          : undefined
                      }
                    />
                  </Box>
                )}
              </InView>
            );
          })}
      </Box>
    </Box>
  );
};
